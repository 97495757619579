import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeView from './views/Home/HomeView';
import AboutView from './views/About/AboutView';
import CareerView from './views/Career/CareerView';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './hooks/scrollToTop';
import SurveyView from './views/Suryey/SurveyView';
import CookiesPolicy from './views/Cookies/CookiesPolicy';
import PrivacyPolicy from './views/Privacy/PrivacyPolicy';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomeView />} />
          <Route path='about' element={<AboutView />} />ƒ
          <Route path='career' element={<CareerView />} />
          <Route path='survey' element={<SurveyView />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='cookies-policy' element={<CookiesPolicy />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </HelmetProvider>
);

reportWebVitals();
