/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd';
import styles from './Footer.module.scss';
import { useNavigate } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';

export default function Footer() {
  const nav = useNavigate();
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const size = useWindowDimensions();

  const columnSpan = size.width > 500 ? 4 : 24;

  return (
    <footer id='Footer' className={styles.footer}>
      <div className={styles.footerColor}>
        <div className={styles.footerContent}>
          <Row gutter={[24, 48]}>
            <Col span={columnSpan} style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={styles.cat} />
            </Col>
            <Col span={columnSpan} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <h6>Компания</h6>
              <Link to='/about'>О нас</Link>
              <Link to='/career'>Карьера</Link>
            </Col>
            <Col span={columnSpan} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <h6>Услуги</h6>
              <a
                onClick={async () => {
                  await nav('/');
                  handleClickScroll('Services');
                }}
              >
                Разработка
              </a>
            </Col>
            <Col
              span={size.width > 500 ? 7 : 24}
              style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}
            >
              <h6>Документы</h6>
              <Link to='/privacy-policy'>Политика конфиденциальности</Link>
              <Link to='/cookies-policy'>Политика использования файлов cookie</Link>
            </Col>
            <Col span={columnSpan} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <h6>Контакты</h6>
              <a href='mailto:info@catlogic.tech&body=привет?subject=вопрос'>info@catlogic.tech</a>
              {/* <a href='tel:+375 29 609 7769'>+375 29 609 7769</a> */}
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
}
