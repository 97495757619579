import React from 'react';

const formattedHeight = (height: number | null) => (height ? `${height}px` : '100vh');

const setProperty = (property: string, value: number | null) => {
  document.documentElement.style.setProperty(property, formattedHeight(value));
};

const getWindowWidth = () => {
  if (typeof window === 'undefined') return null;

  return window.innerWidth;
};

const getWindowHeight = () => {
  if (typeof window === 'undefined') return null;

  return window.innerHeight;
};

export function use100vh(setGlobalVariables = false) {
  const [initialWindowWidth, setInitialWindowWidth] = React.useState(getWindowWidth());
  const [initialWindowHeight, setInitialWindowHeight] = React.useState(getWindowHeight());
  const [resizedWindowHeight, setResizedWindowHeight] = React.useState(getWindowHeight());

  // Set the initial height and width of the window.
  React.useEffect(() => {
    const width = getWindowWidth();
    const height = getWindowHeight();

    setInitialWindowWidth(width);
    setInitialWindowHeight(height);
    setResizedWindowHeight(height);

    if (setGlobalVariables) {
      setProperty('--global-window-h', height);
      setProperty('--resized-window-h', height);
    }
  }, [setGlobalVariables]);

  // Set the height and width of the window when the window is resized.
  React.useEffect(() => {
    const setRealHeight = () => {
      const currentWidth = getWindowWidth();
      const currentHeight = getWindowHeight();

      const isWidthChanged = initialWindowWidth !== currentWidth;
      const isHeightChanged = initialWindowHeight !== currentHeight;

      // Not a resize event caused by the URL bar expanding when scrolling.
      if (isWidthChanged && isHeightChanged) {
        setInitialWindowWidth(currentWidth);
        setInitialWindowHeight(currentHeight);

        if (setGlobalVariables) {
          setProperty('--global-window-h', currentHeight);
        }

        return;
      }

      // Resize event caused by the URL bar expanding when scrolling (only height changed).
      setResizedWindowHeight(currentHeight);

      if (setGlobalVariables) {
        setProperty('--resized-window-h', currentHeight);
      }
    };

    window.addEventListener('resize', setRealHeight);

    return () => window.removeEventListener('resize', setRealHeight);
  }, [initialWindowHeight, initialWindowWidth, setGlobalVariables]);

  return {
    initialWidth: initialWindowWidth,
    initialHeight: initialWindowHeight,
    resizedHeight: resizedWindowHeight,
  };
}
