/* eslint-disable jsx-a11y/anchor-is-valid */
import { List } from 'react-bootstrap-icons';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import { useState } from 'react';

export default function HeaderView() {
  const nav = useNavigate();

  const [isExpand, setExpand] = useState(false);

  const handleClickScroll = async (id: string) => {
    setExpand(false);
    await nav('/');
    const element = document.getElementById(id)?.offsetTop;
    if (element) {
      let size = -30;
      if (id === 'Contacts') {
        if (isMobile) {
          size += 350;
        }
      }

      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo({ top: element + size, behavior: 'smooth' });
    }
  };

  const size = useWindowDimensions();

  const isMobile = size.width < 500;

  const toggleMenu = () => {
    setExpand(!isExpand);
  };

  if (isMobile) {
    return (
      <nav className={styles.navMobile} style={{ height: isExpand ? 360 : 60 }}>
        <div className={styles.content}>
          <div
            className={styles.logo}
            onClick={() => {
              setExpand(false);

              nav('/');
            }}
          />
          <List className={isExpand ? styles.menuActive : styles.menu} onClick={toggleMenu} />
        </div>
        <div className={styles.mobileMenuOptions}>
          <a
            onClick={() => {
              setExpand(false);

              nav('/about');
            }}
          >
            О нас
          </a>
          <a
            onClick={async () => {
              handleClickScroll('Services');
            }}
          >
            Услуги
          </a>
          <a
            onClick={async () => {
              handleClickScroll('Contacts');
            }}
          >
            Контакты
          </a>
          {/* <a
            onClick={async () => {
              handleClickScroll('News');
            }}
          >
            Блог
          </a> */}
          <a
            onClick={() => {
              setExpand(false);
              nav('/career');
            }}
          >
            Карьера
          </a>
          <div
            className={styles.button}
            onClick={() => {
              handleClickScroll('Contacts');
            }}
          >
            Связаться
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContent}>
        <div className={styles.logo} onClick={() => nav('/')} />
        <div className={styles.tabs}>
          <a href='/about'>О нас</a>
          <a
            onClick={async () => {
              handleClickScroll('Services');
            }}
          >
            Услуги
          </a>
          <a
            onClick={async () => {
              handleClickScroll('Contacts');
            }}
          >
            Контакты
          </a>
          {/* <a
            onClick={async () => {
              handleClickScroll('News');
            }}
          >
            Блог
          </a> */}
          <a href='/career'>Карьера</a>
          <div
            className={styles.button}
            onClick={() => {
              nav('/');
              handleClickScroll('Contacts');
            }}
          >
            Связаться
          </div>
        </div>
      </div>
    </nav>
  );
}
