import { Helmet } from 'react-helmet-async';
import styles from './index.module.scss';
import HeaderView from '../../components/Header/HeaderView';
import Footer from '../../components/Footer/Footer';

export default function CookiesPolicy() {
  return (
    <main>
      <Helmet>
        <title>CatLogic | Политика использования файлов cookie</title>
        <link rel='canonical' href='https://www.catlogic.tech/privacy-policy' />
      </Helmet>
      <HeaderView />
      <div className={styles.content}>
        <h1>Правила использования файлов Cookie</h1>
        <div>
          <h2>1. Общие положения</h2>
          <p>
            Настоящая политика использования файлов cookie (далее – Политика) описывает, как сайт
            stafflow.by, catlogic.tech (далее – Сайт) использует файлы cookie и аналогичные
            технологии для улучшения работы пользователей и обеспечения предоставления качественных
            услуг.
          </p>

          <h2>2. Что такое файлы cookie? </h2>
          <p>
            Файлы cookie – это небольшие текстовые файлы, которые сохраняются на устройстве
            пользователя (компьютере, планшете, смартфоне) при посещении веб-сайтов. Файлы cookie
            позволяют сайтам запоминать действия и предпочтения пользователя (например, логин, язык,
            размер шрифта и другие настройки отображения), чтобы пользователю не нужно было заново
            вводить их при каждом посещении сайта или переходе с одной страницы на другую.
          </p>

          <h2>3. Как мы используем файлы cookie? </h2>
          <p>Мы используем файлы cookie для различных целей:</p>
          <ul>
            <li>
              <strong>Необходимые файлы cookie</strong>: Эти файлы cookie необходимы для
              функционирования Сайта. Они позволяют вам перемещаться по Сайту и использовать его
              функции, такие как доступ к защищенным областям Сайта.{' '}
            </li>
            <li>
              <strong>Функциональные файлы cookie</strong>: Эти файлы cookie позволяют Сайту
              запоминать сделанные вами выборы (например, ваше имя пользователя, язык или регион, в
              котором вы находитесь) и предоставлять улучшенные, более персонализированные функции.{' '}
            </li>
            <li>
              <strong>Аналитические файлы cookie</strong>: Эти файлы cookie собирают информацию о
              том, как посетители используют Сайт, например, какие страницы они посещают чаще всего
              и получают ли они сообщения об ошибках с веб-страниц. Эти файлы cookie используются
              для улучшения работы Сайта.{' '}
            </li>
            <li>
              <strong>Рекламные файлы cookie</strong>: Эти файлы cookie используются для показа
              рекламных объявлений, которые более релевантны для вас и ваших интересов. Они также
              используются для ограничения числа показов рекламы и помогают измерять эффективность
              рекламных кампаний.
            </li>
          </ul>

          <h2>4. Управление файлами cookie</h2>
          <h2>4.1. Как вы можете управлять файлами cookie? </h2>
          <p>
            Вы можете контролировать и/или удалять файлы cookie по своему усмотрению – подробности
            смотрите на сайте aboutcookies.org. Вы можете удалить все файлы cookie, которые уже
            находятся на вашем компьютере, а также настроить большинство браузеров на предотвращение
            их размещения. Однако в этом случае вам, возможно, придётся вручную изменять некоторые
            параметры при каждом посещении сайта, и некоторые функции могут не работать.
          </p>

          <h2>4.2. Изменение настроек cookie в браузере </h2>
          <p>
            Большинство веб-браузеров позволяют контролировать файлы cookie через настройки
            браузера. Вы можете настроить браузер так, чтобы он блокировал файлы cookie или
            предупреждал вас, когда файлы cookie отправляются на ваше устройство. Обратитесь к
            документации вашего браузера, чтобы узнать, как изменить настройки cookie.
          </p>

          <h2>4.3. Отказ от аналитических и рекламных файлов cookie </h2>
          <p>
            Чтобы отказаться от использования аналитических и рекламных файлов cookie, вы можете
            воспользоваться инструментами, предлагаемыми сервисами аналитики и рекламными
            платформами. Например, вы можете посетить сайты таких организаций, как Network
            Advertising Initiative (NAI) или Digital Advertising Alliance (DAA), чтобы получить
            информацию о том, как отказаться от получения рекламы, основанной на ваших интересах.
          </p>

          <h2>5. Изменения в Политике </h2>
          <p>
            Мы можем время от времени обновлять настоящую Политику, чтобы отразить изменения в
            используемых нами файлах cookie или по другим операционным, юридическим или нормативным
            причинам. Мы рекомендуем регулярно просматривать эту Политику, чтобы
          </p>
          <h2>6. Контактная информация </h2>
          <p>
            Если у вас есть вопросы по поводу настоящей Политики или нашего использования файлов
            cookie, пожалуйста, свяжитесь с нами по электронной почте: info@catlogic.tech.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
