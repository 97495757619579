import Footer from '../../components/Footer/Footer';
import HeaderView from '../../components/Header/HeaderView';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function SurveyView() {
  const path =
    'https://docs.google.com/forms/d/e/1FAIpQLScmq60UuCU_00s3n39H2hmVPfTFas5VDeicRKPlAyg0ubEsxQ/viewform';

  const { isMobile } = useWindowDimensions();
  return (
    <div>
      <HeaderView />
      <iframe title='myFrame' src={path} width='100%' height={isMobile ? 4000 : 3200} />
      <Footer />
    </div>
  );
}
