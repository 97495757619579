// import { Helmet } from 'react-helmet-async';
// import Footer from '../../components/Footer/Footer';
import HeaderView from '../../components/Header/HeaderView';
// import styles from './Career.module.scss';
// import { Col, Row } from 'antd';
// import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function CareerView() {
  // const size = useWindowDimensions();

  // const columnSpan = size.isMobile ? 24 : 8;

  return (
    <main>
      <HeaderView />
      <iframe
        src='https://app.stafflow.by/job-vacancy/catlogic/careers'
        title='careers'
        style={{ width: '100vw', height: '100vh', border: 'none' }}
      />
    </main>
    // <main>
    //   <Helmet>
    //     <title>CatLogic | Карьера</title>
    //     <link rel='canonical' href='https://www.catlogic.tech/career' />
    //   </Helmet>
    //   <HeaderView />
    //   <div className={styles.content}>
    //     <h1>Карьера</h1>
    //     <h5>
    //       Присоединяйтесь к нашей талантливой команде в <strong>CatLogic</strong>!
    //     </h5>
    //     <Row gutter={[24, 24]}>
    //       <Col span={size.isMobile ? 24 : 10}>
    //         <div className={styles.card}>
    //           <p>
    //             В <strong>CatLogic</strong> мы всегда заинтересованы в умных, творческих и
    //             мотивированных профессионалах, которые готовы присоединиться к нашей динамичной
    //             рабочей среде.
    //             <br />
    //             <br />
    //             Хотя в настоящее время у нас нет открытых вакансий, мы всегда готовы узнать о
    //             талантливых людях, которые могут внести свой вклад в успех нашего бизнеса.
    //           </p>
    //         </div>
    //       </Col>
    //       <Col span={size.isMobile ? 24 : 14}>
    //         <div className={styles.card}>
    //           <h6>Что мы ценим</h6>
    //           <p>• Инновационное мышление</p>
    //           <p>• Стремление к непрерывному обучению и развитию</p>
    //           <p>• Командный дух и вклад в общий успех</p>
    //           <p>• Адаптивность и готовность к решению сложных задач</p>
    //         </div>
    //       </Col>
    //       <Col span={size.isMobile ? 24 : 14}>
    //         <div className={styles.card}>
    //           <h6>Как оставить свои контакты</h6>
    //           <p>
    //             Если вы считаете, что ваш опыт и ваши навыки могут быть ценны для нашей команды,
    //             пожалуйста, отправьте своё резюме и сопроводительное письмо на{' '}
    //             <a href='mailto:info@catlogic.tech&body=привет?subject=Карьера'>
    //               info@catlogic.tech
    //             </a>
    //             <br />
    //             <br />
    //             Мы рассмотрим вашу информацию и свяжемся с вами, когда появится подходящая
    //             возможность.
    //           </p>
    //         </div>
    //       </Col>
    //       <Col span={size.isMobile ? 24 : 10}>
    //         <div className={styles.card}>
    //           <h6>Развитие карьеры в CatLogic</h6>
    //           <p>
    //             Присоединившись к <strong>CatLogic</strong>, вы станете частью прогрессивной
    //             компании, которая ценит ваше развитие и благополучие. Мы обещаем, что ваши идеи
    //             будут услышаны, а достижения — <strong>признаны</strong>.
    //             <br />
    //             <br />
    //             Следите за обновлениями на нашем сайте или в социальных сетях, чтобы быть в курсе
    //             будущих вакансий. Мы благодарим за интерес к CatLogic и с нетерпением ожидаем
    //             возможности приветствовать вас в нашей команде!
    //           </p>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    //   <Footer />
    // </main>
  );
}
